export default {
  English: {
    faqs: [
      {
        question: "I'm not a healthcare worker, can I apply for housing?",
        answer:
          "This service is primarily for healthcare workers who carry the highest risk of contracting COVID-19. However, we understand that all essential workers carry some risk and might be seeking temporary, low-cost housing. If you are concerned about exposing your household to COVID-19, we encourage you to apply.",
        tag: "healthcare",
      },
      {
        question: "Can I list my condo?",
        answer:
          "Condos are appropriate to list, so long as the unit itself is not shared. Common areas in the building should be kept as clean and safe as possible to prevent the spread of the virus.",
        tag: "property",
      },
      {
        question: "Can I use this service to find long-term housing?",
        answer:
          "Our service is only intended to support healthcare workers during the COVID-19 pandemic.  Property owners are listing their units at unprecedented rates in light of these extraordinary circumstances.  Please do not contact property owners with the intention of securing low-cost accommodation indefinitely.",
        tag: "healthcare",
      },
      {
        question:
          "My unit doesn’t have in-suite laundry, should I still list it?",
        answer:
          "While having in-suite laundry makes it easier for healthcare workers to reduce the risk of exposure to others, a unit without it may still be helpful to healthcare workers.  We encourage you to list your unit as an option for healthcare workers to consider.",
        tag: "property",
      },
      {
        question: "Can I list my unfurnished suite?",
        answer:
          "At this time, we are only looking for furnished suites that healthcare workers can move into quickly and easily.  You're welcome to list your unfurnished suite and leave it up to the worker's discretion as to whether or not it suits their needs.",
        tag: "property",
      },
      {
        question: "Can I donate money or supplies to healthcare workers?",
        answer: `Unfortunately, we do not have the capacity to accept or distribute donations.  We recommend making donations through other organizations, such as <a href="https://thehomefront.ca/" target="_blank" rel="noopener noreferrer">The Home Front</a> or the <a href="https://www.redcross.ca/" target="_blank" rel="noopener noreferrer">Canadian Red Cross</a>.`,
        tag: "other",
      },
      {
        question: "Isn't Airbnb already doing this?",
        answer: `Yes, Airbnb has started a <a href="https://www.airbnb.ca/openhomes/covid19relief" target="_blank" rel="noopener noreferrer">COVID-19 relief program</a> for healthcare workers and first responders.  Listing a property on Airbnb is an involved process that takes a number of steps. We offer a simpler and less formal process so that property owners can be connected with healthcare workers faster.`,
        tag: "other",
      },
      {
        question: "Can I contact you for media enquiries?",
        answer:
          'Yes! For media enquiries, please email victoria&#64;healthworkerhousing.ca with "Media Enquiry" in the subject line.',
        tag: "other",
      },
      {
        question:
          "Aren’t hotels already offering free accommodation to healthcare workers?",
        answer:
          "We applaud the generous offers made by these hotels and organizations! However, hotel rooms often lack amenities that healthcare workers need, such as a kitchen or kitchenette and laundry facilities.  In addition, most hotels offering rooms are located in the downtown core, and are therefore not helpful to those who work farther out of town.",
        tag: "other",
      },
      {
        question: "Can I start this in another city?",
        answer:
          'Yes! Email victoria&#64;healthworkerhousing.ca with "New City" in the subject line, and we will help you get started. You only need know how to use email and Google Forms to act as a city coordinator.',
        tag: "other",
      },
    ],
  },
  French: {
    faqs: [
      {
        question:
          "Si je ne suis pas travailleu.r.e.s de la santé, puis-je demander un logement?",
        answer:
          "Ce service est destiné principalement aux travailleur.e.s de la santé qui présentent un risque plus élevé de contracter le COVID-19. Cependant, nous comprenons que tous les aidant.e.s naturel.le.s sont aussi à risques et pourraient se chercher un logement temporaire. Si vous êtes préoccupé par l’exposition de votre famille ou colocataire.s au COVID-19, nous vous encourageons à remplir une demande.",
        tag: "healthcare",
      },
      {
        question: "Puis-je inscrire mon condominium?",
        answer:
          "Les condominiums acceptés dans la mesure où l’unité n’est pas partagée. Les espaces communs doivent être gardés propre et sécuritaire pour éviter la propagation du virus.",
        tag: "property",
      },
      {
        question:
          "Puis-je utiliser ce service à des fins de logement à long-terme?",
        answer:
          "Notre service est destiné uniquement à soutenir les travailleur.e.s de la santé pendant la pandémie de la COVID-19. À la lumière de ces circonstances extraordinaires, les propriétaires auront inscrit leur logements aux tarifs le plus bas. Nous vous remercions de ne pas contacter les propriétaires avec l’intention de sécuriser un logement à faible coût sur le long-terme.",
        tag: "healthcare",
      },
      {
        question:
          "Je ne dispose pas d’une salle de lavage dans mon logement, devrais-je l’annoncer quand même?",
        answer:
          "Avoir la salle de lavage dans le logement facilite la réduction du risque d'exposition des autres avec les travailleur.e.s de la santé. Cependant, une unité sans peut toujours leur être utile. Nous vous encourageons à inscrire votre logement comme une option à envisager par les professionnel.le.s de la santé.",
        tag: "property",
      },
      {
        question: "Puis-je inscrire mon logement non-meublé?",
        answer:
          "Pour le moment, nous recherchons uniquement des logements meublés pour que les travailleur.e.s de la santé puissent emménager facilement et rapidement. Nous vous invitons à inscrire votre logement non-meublé cependant, il incombe aux travailleur.e.s de savoir si le logement réponds à leurs besoins.",
        tag: "property",
      },
      {
        question:
          "Puis-je faire des donations aux travailleur.e.s de la santé?",
        answer: `Malheureusement, nous n'avons pas la capacité d'accepter ou de distribuer des dons. Nous vous recommandons de faire des dons par le biais d'autres organisations, comme <a href="https://thehomefront.ca/" target="_blank" rel="noopener noreferrer">The Home Front</a> ou la <a href="https://www.croixrouge.ca/" target="_blank" rel="noopener noreferrer">Croix-Rouge canadienne</a>.`,
        tag: "other",
      },
      {
        question: "Est-ce qu’Airbnb fait la même chose?",
        answer: `Airbnb a lancé un <a href="https://www.airbnb.ca/openhomes/covid19relief" target="_blank" rel="noopener noreferrer">programme de secours pour la pandémie de la COVID-19</a> pour les travailleur.e.s de la santé et les premiers répondants. S’inscrire sur Airbnb requiert un certains nombre d’étapes, ce qui peut paraître un processus complexe. Ce que nous proposons est un processus simple et moins formel afin de faciliter un échange rapide entre les propriétaires et les professionnel.les de la santé`,
        tag: "other",
      },
      {
        question:
          "Puis-je vous contacter pour les demandes de renseignements des médias?",
        answer: `Pour les demandes de renseignements des médias, veuillez envoyer un courriel à montreal@healthworkerhousing.ca avec l'objet «Demande médiatique».`,
        tag: "other",
      },
      {
        question:
          "Qu’en est-il des hôtels et de leur offre d’hébergement pour les professionnel.le.s de la santé?",
        answer:
          "Nous saluons l’offre généreuses des hôtels et des organisations qui offrent ce service. Cependant, les chambres d’hôtel n’offrent pas tous les équipements dont les travailleur.e.s de la santé ont besoin, comme par exemple, une cuisine ou cuisinette ou une salle de lavage. De plus, la plupart des hôtels qui proposent ce service sont situés au centre-ville et ne sont donc pas utiles à ceux qui travaillent dans les autres arrondissements de la ville.",
        tag: "other",
      },
      {
        question: "Comment puis-je inscrire une autre ville?",
        answer: `Envoyez un courriel à victoria@healthworkerhousing.ca avec l'objet  «Nouvelle ville» et nous vous aiderons à démarrer le processus. Pour agir en tant que coordinateur de la ville, vous avez besoin de savoir comment gérer une boîte courriel et les formulaires Google.`,
        tag: "other",
      },
    ],
  },
};
